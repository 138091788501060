import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import NewsDetailsPage from '~/components/Ambassador/NewsDetailsPage'

// TODO make this component private: Only ambassador account has access
const NewsTemplate = ({ data }) => {
  return (
    <Layout>
      <Metadata title="News" />
      <NewsDetailsPage news={data.contentfulNews} />
    </Layout>
  )
}

export const query = graphql`
  query NewsDetails($slug: String = "") {
    contentfulNews(slug: { eq: $slug }) {
      id
      title
      description {
        raw
      }
      image {
        gatsbyImageData(cropFocus: CENTER, layout: CONSTRAINED, height: 400)
        title
      }
    }
  }
`

export default NewsTemplate
