import React from 'react'
import { ContentfulRichTextOptions } from '~/components/ContentfulRichTextOptions'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Button } from 'react-bootstrap'
import LatestEvents from '~/components/Ambassador/LatestEvents'

const NewsDetailsPage = ({ news }) => {
  const richTextOptions = ContentfulRichTextOptions
  const { title, id, description, image } = news
  const gImage = getImage(image)

  //TODO copy clipboard affiliate code
  function handleCopyUrl() {
    alert('url copied.....TODO' + id)
  }

  return (
    <div className="container font-sfpro py-5">
      <div className="row">
        <div className="col-12">
          <GatsbyImage image={gImage} alt={image.title} className="img-fluid w-100" />
        </div>
      </div>
      <div className="row mt-6 mb-5">
        <div className="col-md-8">
          <div className="row">
            <div className="col-12">
              <h1 className="font-opensans-light fs-60 m-0">{title}</h1>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">{description && renderRichText(description, richTextOptions)}</div>
          </div>
        </div>
        <div className="col-md-4">
          <Button variant="primary" className="fs-24 px-5 py-2 mx-0 rounded-0 w-100" onClick={handleCopyUrl}>
            COPY YOUR CUSTOM SHOP URL
          </Button>
        </div>
      </div>
      <LatestEvents />
    </div>
  )
}
NewsDetailsPage.propTypes = {
  news: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.shape({}),
    image: PropTypes.shape({})
  })
}
export default NewsDetailsPage
